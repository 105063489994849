import { useState, useMemo } from 'react';
import { Search, Circle, Truck, MapPin, CheckCircle2 } from 'lucide-react';

const generateStops = (start: string, end: string, count: number) => {
  const stops = [start];
  for (let i = 1; i < count - 1; i++) {
    stops.push(`Stop ${i}`);
  }
  stops.push(end);
  return stops;
};

const shipmentData = [
  { 
    id: '105921', 
    from: 'Delhi', 
    to: 'Varanasi', 
    date: '16 Jun, 2:00 PM', 
    available: 20, 
    total: 200, 
    percentage: 90, 
    truck: 'Tata Ace',
    stops: ['Delhi', 'Agra', 'Kanpur', 'Allahabad', 'Varanasi'],
    status: 'Arrival'
  },
  { 
    id: '107282', 
    from: 'Mumbai', 
    to: 'Kolkata', 
    date: '16 Jun, 12:30 PM', 
    available: 100, 
    total: 200, 
    percentage: 50, 
    truck: 'Tata 407',
    stops: generateStops('Mumbai', 'Kolkata', 100),
    status: 'In Transit'
  },
  { 
    id: '108293', 
    from: 'Bangalore', 
    to: 'Chennai', 
    date: '17 Jun, 9:00 AM', 
    available: 50, 
    total: 150, 
    percentage: 67, 
    truck: 'Ashok Leyland',
    stops: ['Bangalore', 'Hosur', 'Vellore', 'Chennai'],
    status: 'Departure'
  },
  { 
    id: '109304', 
    from: 'Hyderabad', 
    to: 'Pune', 
    date: '18 Jun, 3:00 PM', 
    available: 30, 
    total: 180, 
    percentage: 83, 
    truck: 'Mahindra',
    stops: ['Hyderabad', 'Solapur', 'Pune'],
    status: 'Available'
  },
  { 
    id: '110315', 
    from: 'Ahmedabad', 
    to: 'Jaipur', 
    date: '19 Jun, 7:00 AM', 
    available: 0, 
    total: 220, 
    percentage: 100, 
    truck: 'Eicher',
    stops: ['Ahmedabad', 'Udaipur', 'Ajmer', 'Jaipur'],
    status: 'Completed'
  }
];

const statusData = [
  { label: 'Arrival', count: 20 },
  { label: 'Available', count: 5 },
  { label: 'Departure', count: 32 },
  { label: 'In Transit', count: 15 },
  { label: 'Delayed', count: 3 },
  { label: 'Completed', count: 50 },
  { label: 'Cancelled', count: 2 },
  { label: 'Pending', count: 8 },
  { label: 'Processing', count: 12 },
  { label: 'On Hold', count: 4 },
];

interface ShipmentProps {
  setCurrentPage: (page: string) => void;
}

export default function Shipment({ setCurrentPage }: ShipmentProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeStatuses, setActiveStatuses] = useState<string[]>([]);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const filteredShipments = useMemo(() => {
    if (activeStatuses.length === 0) return shipmentData;
    return shipmentData.filter(shipment => activeStatuses.includes(shipment.status));
  }, [activeStatuses]);

  const toggleStatus = (status: string) => {
    setActiveStatuses(prev => 
      prev.includes(status) 
        ? prev.filter(s => s !== status)
        : [...prev, status]
    );
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      {/* Sidebar */}
      <aside className={`bg-white w-full md:w-64 md:min-h-screen flex-shrink-0 ${sidebarOpen ? 'block' : 'hidden'} md:block`}>
        <div className="p-4 border-b">
          <h2 className="text-xl font-semibold">ERP Sampann</h2>
        </div>
        <nav className="flex-grow">
        <button onClick={() => setCurrentPage('dashboard')} className="w-full text-left block py-2 px-4 text-gray-600 hover:bg-gray-100">Dashboard</button>
          <button onClick={() => setCurrentPage('shipment')} className="w-full text-left block py-2 px-4 text-blue-600 bg-blue-100 border-l-4 border-blue-600">Shipment</button>
          <button  onClick={() => setCurrentPage('map')} className="w-full text-left block py-2 px-4 text-gray-600 hover:bg-gray-100">Map</button>
        </nav>
        <div className="p-4 border-t">
          <div className="flex items-center">
            <img src="/placeholder.svg?height=40&width=40" alt="User" className="w-10 h-10 rounded-full mr-3" />
            <div>
              <p className="font-semibold">Dhruv Singh</p>
              <p className="text-sm text-gray-500">Operation Manager</p>
            </div>
          </div>
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-grow p-6 md:p-8 overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold">Shipment</h1>
          <button onClick={() => setSidebarOpen(!sidebarOpen)} className="md:hidden">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        {/* Search */}
        <div className="mb-6 relative">
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Search by tracking number"
              className="w-full p-2 pl-10 pr-4 border rounded-md"
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
          </div>
        </div>

        {/* Shipment Status */}
        <div className="bg-white p-4 rounded-lg shadow mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Shipment</h2>
          </div>
          
          {/* Scrollable Status Buttons */}
          <div className="overflow-x-auto pb-2 mb-4">
            <div className="flex space-x-4 min-w-max">
              {statusData.map((status) => (
                <StatusButton
                  key={status.label}
                  label={status.label}
                  count={status.count}
                  active={activeStatuses.includes(status.label)}
                  onClick={() => toggleStatus(status.label)}
                />
              ))}
            </div>
          </div>
          
          {/* Shipment List */}
          <div className="space-y-4">
            {filteredShipments.map((shipment:any) => (
              <ShipmentItem 
                key={shipment.id} 
                shipment={shipment} 
                onClick={() => setSelectedShipment(shipment)}
              />
            ))}
          </div>
        </div>
      </main>

      {/* Modal */}
      {selectedShipment && (
        <ShipmentModal 
          shipment={selectedShipment} 
          onClose={() => setSelectedShipment(null)} 
        />
      )}
    </div>
  );
}

function StatusButton({ label, count, active, onClick }: { label: string, count: number, active: boolean, onClick: () => void }) {
  return (
    <button
      onClick={onClick}
      className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
        active ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
      }`}
    >
      {label} ({count})
    </button>
  );
}

function ShipmentItem({ shipment, onClick }: { shipment: any, onClick: () => void }) {
  return (
    <div 
      className="flex items-center justify-between p-4 bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors"
      onClick={onClick}
    >
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <Truck className="h-10 w-10 text-gray-500" />
        </div>
        <div>
          <h3 className="font-medium">{shipment.from} - {shipment.to}</h3>
          <p className="text-sm text-gray-500">{shipment.date}</p>
          <p className="text-sm text-gray-500">Shipment number: #{shipment.id}</p>
          <p className="text-sm text-gray-500">Truck: {shipment.truck}</p>
          <p className="text-sm text-blue-600">Status: {shipment.status}</p>
        </div>
      </div>
      <div className="text-right flex flex-col items-end">
        <p className="text-lg font-bold text-blue-600">{shipment.percentage}%</p>
        <p className="text-sm text-gray-500">Available: {shipment.available}/{shipment.total}</p>
        <div className="w-32 bg-gray-200 rounded-full h-2 mt-2">
          <div
            className="bg-blue-600 h-2 rounded-full"
            style={{ width: `${shipment.percentage}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

function ShipmentModal({ shipment, onClose }: { shipment: any, onClose: () => void }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full max-h-[90vh] flex flex-col">
        <div className="p-6 flex-shrink-0">
          <h2 className="text-xl font-semibold mb-4">Shipment Journey</h2>
        </div>
        <div className="flex-grow overflow-y-auto px-10">
          <ol className="relative border-s border-gray-390">
            {shipment.stops.map((stop: string, index: number) => (
              <li key={index} className="mb-10 ms-6">
                <span className={`absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ${
                  index === 0 ? 'bg-green-200' : 
                  index === shipment.stops.length - 1 ? 'bg-red-200' : 
                  'bg-gray-100'
                }`}>
                  {index === 0 ? (
                    <CheckCircle2 className="text-green-500 text-green-400" />
                  ) : index === shipment.stops.length - 1 ? (
                    <MapPin className="text-red-500 dark:text-red-400" />
                  ) : (
                    <Circle className="text-gray-500 dark:text-gray-400" />
                  )}
                </span>
                <h3 className="font-medium leading-tight">{stop}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {index === 0 ? 'Origin' : index === shipment.stops.length - 1 ? 'Destination' : `Stop ${index}`}
                </p>
              </li>
            ))}
          </ol>
        </div>
        <div className="p-6 flex-shrink-0">
          <button 
            onClick={onClose}
            className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}