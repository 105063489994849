import React, { useState } from 'react';
import Dashboard from './components/Dashboard';
import Shipment from './components/Shipment';
import Map from './components/Map';

export default function App() {
  const [currentPage, setCurrentPage] = useState('dashboard');

  return (
    <div>
      {currentPage === 'dashboard' && <Dashboard setCurrentPage={setCurrentPage} /> }
      {currentPage === 'shipment' && <Shipment setCurrentPage={setCurrentPage} />}
      {currentPage === 'map' && <Map setCurrentPage={setCurrentPage} />}
    </div>
  );
}