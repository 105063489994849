import { useState } from 'react';
import { Bell, Package, Truck, MapPin, Search } from 'lucide-react';
import ShipmentChart from '../ShipmentChart';
import CircularProgressChart from '../CircularProgressChart';

export default function Dashboard({ setCurrentPage }: any) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const shipmentData = [
    { date: '2023-06-01', shipments: 80 },
    { date: '2023-06-02', shipments: 95 },
    { date: '2023-06-03', shipments: 110 },
    { date: '2023-06-04', shipments: 105 },
    { date: '2023-06-05', shipments: 120 },
    { date: '2023-06-06', shipments: 130 },
    { date: '2023-06-07', shipments: 125 },
  ];

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      {/* Sidebar */}
      <aside className={`bg-white w-full md:w-64 md:min-h-screen flex-shrink-0 ${sidebarOpen ? 'block' : 'hidden'} md:block`}>
        <div className="p-4 border-b">
          <h2 className="text-xl font-semibold">ERP Sampann</h2>
        </div>
        <nav className="flex-grow">
          <button onClick={() => setCurrentPage('dashboard')} className="w-full text-left block py-2 px-4 text-blue-600 bg-blue-100 border-l-4 border-blue-600">Dashboard</button>
          <button onClick={() => setCurrentPage('shipment')} className="w-full text-left block py-2 px-4 text-gray-600 hover:bg-gray-100">Shipment</button>
          <button onClick={() => setCurrentPage('map')} className="w-full text-left block py-2 px-4 text-gray-600 hover:bg-gray-100">Map</button>
        </nav>
        <div className="p-4 border-t">
          <div className="flex items-center">
            <img src="/placeholder.svg?height=40&width=40" alt="User" className="w-10 h-10 rounded-full mr-3" />
            <div>
              <p className="font-semibold">Dhruv Singh</p>
              <p className="text-sm text-gray-500">Operation Manager</p>
            </div>
          </div>
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-grow p-6 md:p-8 overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold">Dashboard</h1>
          <button onClick={() => setSidebarOpen(!sidebarOpen)} className="md:hidden">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        {/* Search Bar */}
        <div className="mb-6 relative">
          <input
            type="text"
            placeholder="Search by tracking number"
            className="w-full p-2 pl-10 pr-4 border rounded-md"
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>

        {/* Overview Cards */}
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 mb-6">
          <OverviewCard title="New Package" value="224" icon={<Package className="text-blue-500" />} />
          <OverviewCard title="Ready to Shipping" value="60" icon={<Package className="text-yellow-500" />} />
          <OverviewCard title="In Transit" value="200" icon={<Truck className="text-green-500" />} />
          <OverviewCard title="Delivered" value="3600" icon={<MapPin className="text-purple-500" />} />
        </div>

        {/* Shipment Chart */}
        <div className="bg-white p-4 rounded-lg shadow mb-6">
          <h2 className="text-lg font-semibold mb-4">Daily Shipments</h2>
          <ShipmentChart data={shipmentData} />
        </div>

        {/* Delayed Delivery */}
        <div className="bg-white p-4 rounded-lg shadow mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Delayed delivery</h2>
            <button className="text-blue-600 text-sm hover:underline">Show all</button>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-left text-gray-500">
                  <th className="pb-2 pr-4">Destination</th>
                  <th className="pb-2 pr-4">Truck Number</th>
                  <th className="pb-2 pr-4">Arrive Time</th>
                  <th className="pb-2">Time Delay</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-2 pr-4">Delhi - Mumbai</td>
                  <td className="pr-4">DL04TF2309</td>
                  <td className="pr-4">07:34AM</td>
                  <td className="text-red-500">5:45 hr</td>
                </tr>
                <tr>
                  <td className="py-2 pr-4">Lucknow - Ajmer</td>
                  <td className="pr-4">DL04TF2121</td>
                  <td className="pr-4">09:13AM</td>
                  <td className="text-orange-500">2:15 hr</td>
                </tr>
                <tr>
                  <td className="py-2 pr-4">Pune - Bangalore</td>
                  <td className="pr-4">DL04TA7639</td>
                  <td className="pr-4">12:44PM</td>
                  <td className="text-yellow-500">0:35 hr</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Daily Plan */}
        <div className="bg-white p-4 rounded-lg shadow mb-6">
          <h2 className="text-lg font-semibold mb-4">Daily Plan <span className="text-sm font-normal text-gray-500">[Wed 20 Jan]</span></h2>
          <div className="flex flex-col md:flex-row items-center justify-between mb-4">
            <div className="space-y-1 mb-4 md:mb-0">
              <p className="text-sm font-medium text-gray-500">Shipment processed</p>
              <p className="text-2xl font-bold">1278 <span className="text-base font-normal text-gray-500">/ 1856</span></p>
            </div>
            <CircularProgressChart value={69} />
          </div>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between text-sm mb-1">
                <span>Order processed</span>
                <span className="font-medium">830 / 1230</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: '67%' }}></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between text-sm mb-1">
                <span>Request considered</span>
                <span className="font-medium">10 / 20</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: '50%' }}></div>
              </div>
            </div>
          </div>
        </div>

        {/* Available Truck */}
        <div className="bg-white p-4 rounded-lg shadow mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Available Truck</h2>
            <button className="text-blue-600 text-sm hover:underline">Show all</button>
          </div>
          <div className="space-y-4">
            <TruckAvailability truckNumber="DL04T8730" route="Lucknow - Delhi" availability={90} />
            <TruckAvailability truckNumber="DL04TA6161" route="Mumbai - Pune" availability={60} />
            <TruckAvailability truckNumber="DL04TU8821" route="Pune - Lucknow" availability={50} />
          </div>
        </div>

        {/* Recent Request */}
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Recent Request</h2>
            <button className="text-blue-600 text-sm hover:underline">Show all</button>
          </div>
          <div className="space-y-4">
            <RecentRequest
              icon={<Package className="mr-2 h-4 w-4 flex-shrink-0" />}
              title="Parcel redirection"
              description="Destination: Lucknow - Delhi"
              id="UP701U7890"
            />
            <RecentRequest
              icon={<Truck className="mr-2 h-4 w-4 flex-shrink-0" />}
              title="Truck Tire Burst"
              description="Destination: Pune - Delhi"
              id="UP701U7890"
            />
            <RecentRequest
              icon={<Bell className="mr-2 h-4 w-4 flex-shrink-0" />}
              title="Engine Breakdown"
              description="Destination: Delhi - Kanpur"
              id="UP701U7890"
            />
          </div>
        </div>
      </main>
    </div>
  );
}

function OverviewCard({ title, value, icon }: any) {
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        {icon}
      </div>
      <p className="text-2xl font-bold">{value}</p>
    </div>
  );
}

function TruckAvailability({ truckNumber, route, availability }: any) {
  return (
    <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
      <div className="mb-2 md:mb-0">
        <p className="font-medium">{truckNumber}</p>
        <p className="text-sm text-gray-500">{route}</p>
      </div>
      <div className="flex items-center w-full md:w-auto">
        <div className="w-full md:w-24 bg-gray-200 rounded-full h-2.5 mr-2">
          <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${availability}%` }}></div>
        </div>
        <span className="text-sm font-medium">{availability}%</span>
      </div>
    </div>
  );
}

function RecentRequest({ icon, title, description, id }: any) {
  return (
    <div className="flex items-center">
      {icon}
      <div className="ml-4 space-y-1 flex-grow">
        <p className="text-sm font-medium leading-none">{title}</p>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
      <div className="ml-auto font-medium">{id}</div>
    </div>
  );
}