import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, FeatureGroup, Polyline, CircleMarker, Marker, useMap } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import { LatLngTuple, LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { Search, MapPin, Trash2, CheckCircle2, Circle } from 'lucide-react';


import L from 'leaflet';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
    iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

interface MapProps {
    setCurrentPage: (page: string) => void;
}

interface Geofence {
    id: string;
    name: string;
    type: string;
}

interface StopStatus {
    visited: boolean;
}

const STOP_RADIUS = 0.001; // Approximately 100 meters

const MovingMarker: React.FC<{ route: LatLngTuple[], stops: LatLngTuple[], onUpdateStatus: (statuses: StopStatus[]) => void }> = ({ route, stops, onUpdateStatus }) => {
    const [position, setPosition] = useState<LatLngTuple>([0,0]);
    const map = useMap();
    const markerRef = useRef<L.Marker>(null);
    const [currentSegment, setCurrentSegment] = useState(0);
    const [segmentProgress, setSegmentProgress] = useState(0);
    const [stopStatuses, setStopStatuses] = useState<StopStatus[]>(stops.map(() => ({ visited: false })));

    const resetJourney = useCallback(() => {
        setPosition(route[0]);
        setCurrentSegment(0);
        setSegmentProgress(0);
        setStopStatuses(stops.map(() => ({ visited: false })));
    }, [route, stops]);
    

    useEffect(() => {
        const interpolatePosition = (start: LatLngTuple, end: LatLngTuple, fraction: number): LatLngTuple => {
            return [
                start[0] + (end[0] - start[0]) * fraction,
                start[1] + (end[1] - start[1]) * fraction
            ];
        };

        const isWithinStopRadius = (markerPos: LatLngTuple, stopPos: LatLngTuple): boolean => {
            const dx = markerPos[0] - stopPos[0];
            const dy = markerPos[1] - stopPos[1];
            return Math.sqrt(dx * dx + dy * dy) <= STOP_RADIUS;
        };

        const moveMarker = () => {
            if (currentSegment < route.length - 1) {
                const start = route[currentSegment];
                const end = route[currentSegment + 1];
                const newPosition = interpolatePosition(start, end, segmentProgress);
                setPosition(newPosition);

                if (markerRef.current) {
                    const latLng = new LatLng(newPosition[0], newPosition[1]);
                    markerRef.current.setLatLng(latLng);
                    map.setView(latLng);
                }

                setSegmentProgress(prevProgress => {
                    const newProgress = prevProgress + 0.01;
                    if (newProgress >= 1) {
                        setCurrentSegment(prevSegment => prevSegment + 1);
                        return 0;
                    }
                    return newProgress;
                });

                // Check if the marker entered any stop's geofence
                stops.forEach((stop, index) => {
                    if (!stopStatuses[index].visited && isWithinStopRadius(newPosition, stop)) {
                        setStopStatuses(prevStatuses => {
                            const newStatuses = [...prevStatuses];
                            newStatuses[index].visited = true;
                            return newStatuses;
                        });
                    }
                });
            } else {
                resetJourney();
            }
        };

        const interval = setInterval(moveMarker, 50);

        return () => clearInterval(interval);
    }, [route, stops, map, currentSegment, segmentProgress, stopStatuses, resetJourney]);

    useEffect(() => {
        onUpdateStatus(stopStatuses);
    }, [stopStatuses, onUpdateStatus]);

    return <Marker position={position} ref={markerRef} />;
};

export default function Map({ setCurrentPage }: MapProps) {
    const [center] = useState<LatLngTuple>([24.4539, 54.3773]);
    const ZOOM_LEVEL = 12;
    const mapRef = useRef<L.Map | null>(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [geofences, setGeofences] = useState<Geofence[]>([]);
    const featureGroupRef = useRef<L.FeatureGroup | null>(null);
    const [stopStatuses, setStopStatuses] = useState<StopStatus[]>([]);

    const route: LatLngTuple[] = [
        [21.3539, 51.2773],
        [22.3539, 52.2773],
        [23.3539, 53.2773],
        [24.3539, 54.2773],
        [24.4539, 54.3773],
        [24.4637, 54.3650],
        [24.4800, 54.3700],
        [20.3539, 50.2773],
        [24.4830, 54.3530],
        [24.4900, 54.3610],
    ];

    const stops: LatLngTuple[] = [
        [24.4539, 54.3773],
        [24.4637, 54.3650],
        [24.4720, 54.3570],
        [24.4830, 54.3530],
        [24.4900, 54.3610],
    ];

    const _created = (e: any) => {
        console.log(e);
        const { layerType, layer } = e;
        const newGeofence: Geofence = {
            id: layer._leaflet_id.toString(),
            name: `${layerType} ${geofences.length + 1}`,
            type: layerType
        };
        setGeofences(prevGeofences => [...prevGeofences, newGeofence]);
    };

    const _deleted = (e: any) => {
        const { layers } = e;
        layers.eachLayer((layer: any) => {
            setGeofences(prevGeofences =>
                prevGeofences.filter(geofence => geofence.id !== layer._leaflet_id.toString())
            );
        });
    };

    const handleDeleteGeofence = (id: string) => {
        setGeofences(prevGeofences => prevGeofences.filter(geofence => geofence.id !== id));
        if (featureGroupRef.current) {
            const layersToRemove = [];
            featureGroupRef.current.eachLayer((layer: any) => {
                if (layer._leaflet_id.toString() === id) {
                    layersToRemove.push(layer);
                }
            });
            layersToRemove.forEach(layer => featureGroupRef.current?.removeLayer(layer));
        }
    };

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.invalidateSize();
        }
    }, [sidebarOpen]);

    const handleUpdateStatus = (newStatuses: StopStatus[]) => {
        setStopStatuses(newStatuses);
    };

    return (
        <div className="flex flex-col md:flex-row h-screen bg-gray-100">
            {/* Sidebar */}
            <aside className={`bg-white w-full md:w-64 md:min-h-screen flex-shrink-0 ${sidebarOpen ? 'block' : 'hidden'} md:block`}>
                <div className="p-4 border-b">
                    <h2 className="text-xl font-semibold">ERP Sampann</h2>
                </div>
                <nav className="flex-grow">
                    <button onClick={() => setCurrentPage('dashboard')} className="w-full text-left block py-2 px-4 text-gray-600 hover:bg-gray-100">Dashboard</button>
                    <button onClick={() => setCurrentPage('shipment')} className="w-full text-left block py-2 px-4 text-gray-600 hover:bg-gray-100">Shipment</button>
                    <button onClick={() => setCurrentPage('map')} className="w-full text-left block py-2 px-4 text-blue-600 bg-blue-100 border-l-4 border-blue-600">Map</button>
                </nav>
                <div className="p-4 border-t">
                    <div className="flex items-center">
                        <img src="/placeholder.svg?height=40&width=40" alt="User" className="w-10 h-10 rounded-full mr-3" />
                        <div>
                            <p className="font-semibold">Dhruv Singh</p>
                            <p className="text-sm text-gray-500">Operation Manager</p>
                        </div>
                    </div>
                </div>
            </aside>

            {/* Main Content */}
            <main className="flex-grow p-6 md:p-8 overflow-y-auto">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-2xl font-semibold">Dashboard</h1>
                    <button onClick={() => setSidebarOpen(!sidebarOpen)} className="md:hidden">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>

                {/* Search Bar */}
                <div className="mb-6 relative">
                    <input
                        type="text"
                        placeholder="Search by tracking number"
                        className="w-full p-2 pl-10 pr-4 border rounded-md"
                    />
                    <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
                </div>

                {/* Map and Geofence List Container */}
                <div className="bg-white p-4 rounded-lg shadow mb-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold">Geocercas</h2>
                    </div>
                    <div className='flex flex-col lg:flex-row'>
                        {/* Map Container */}
                        <div className="w-full lg:w-2/3 h-[50vh] lg:h-[70vh] mb-4 lg:mb-0 lg:mr-4">
                            <MapContainer
                                center={center}
                                zoom={ZOOM_LEVEL}
                                style={{ height: '100%', width: '100%' }}
                                ref={mapRef}
                            >
                                <FeatureGroup ref={featureGroupRef}>
                                    <EditControl
                                        position="topright"
                                        onCreated={_created}
                                        onDeleted={_deleted}
                                        draw={{
                                            rectangle: true,
                                            circle: true,
                                            circlemarker: true,
                                            marker: true,
                                            polyline: true,
                                            polygon: true,
                                        }}
                                    />
                                </FeatureGroup>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Polyline positions={stops} color="blue" />
                                {stops.map((position, index) => (
                                    <CircleMarker
                                        key={index}
                                        center={position}
                                        radius={10}
                                        fillColor={index === 0 || index === stops.length - 1 ? "blue" : "gray"}
                                        color={index === 0 || index === stops.length - 1 ? "blue" : "gray"}
                                        fillOpacity={0.5}
                                    />
                                ))}
                                <MovingMarker route={route} stops={stops} onUpdateStatus={handleUpdateStatus} />
                            </MapContainer>
                        </div>

                        {/* Geofence List */}
                        <div className="w-full lg:w-1/3 h-[30vh] lg:h-[70vh] overflow-y-auto">
                            {geofences.map((geofence) => (
                                <div key={geofence.id} className="flex items-center justify-between p-2 bg-gray-50 rounded mb-2">
                                    <div className="flex items-center">
                                        <MapPin className="mr-2 text-blue-500" size={20} />
                                        <span>{geofence.name} ({geofence.type})</span>
                                    </div>
                                    <button
                                        onClick={() => handleDeleteGeofence(geofence.id)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        <Trash2 size={20} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Marker Status */}
                <div className="bg-white p-4 rounded-lg shadow mb-6">
                    <h2 className="text-lg font-semibold mb-4">Marker Status</h2>
                    <div className="flex overflow-x-auto">
                        {stops.map((stop, index) => (
                            <div key={index} className="flex-shrink-0 w-24 mx-2 text-center">
                                <div className={`w-12 h-12 mx-auto rounded-full flex items-center justify-center ${
                                    stopStatuses[index]?.visited ? 'bg-green-200' :
                                    index === 0 || index === stops.length - 1 ? 'bg-blue-200' : 
                                    'bg-gray-200'
                                }`}>
                                    {stopStatuses[index]?.visited ? (
                                        <CheckCircle2 className="text-green-500" size={24} />
                                    ) : index === 0 || index === stops.length - 1 ? (
                                        <MapPin className="text-blue-500" size={24} />
                                    ) : (
                                        <Circle className="text-gray-500" size={24} />
                                    )}
                                </div>
                                <p className="mt-2 text-sm font-medium">
                                    {index === 0 ? 'Origin' : index === stops.length - 1 ? 'Destination' : `Stop ${index}`}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </main>
        </div>
    );
}